'use strict';

const API_URL = 'https://api.nefting.com'

document.getElementById("cta").onclick = function () {
    location.href = "https://app.nefting.com";
};

document.getElementById("email").onclick = async () => {
    const email = document.getElementById('emailInput').value;

    const validateEmail = String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

    if (validateEmail) {
        // TODO : API CALL
        const response = await fetch(`${API_URL}/newsletter/landing/add`, {
            method: 'POST',
            body: JSON.stringify({
                email
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const myJson = await response.json();

        console.log('res : ', myJson);

        document.getElementById('emailInput').style.border = 'transparent';
        document.getElementById('emailRegistered').style.display = 'block';

    } else {
        document.getElementById('emailInput').style.border = '2px solid red';
    }
};
